import {
  Button,
  Layout,
  LayoutItem,
  useStyledTheme,
  ContentCard,
  Text,
} from "@audi/audi-ui-react";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { backendConfig } from "../backendConfig"; // Assuming you have an authConfig file
import Panel from "../components/Panel/Panel";

function Home() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, regionInfo } = useContext(AuthContext);

  useEffect(() => {
    fetch(
      `${backendConfig.host}${backendConfig.configuration}tiles/${regionInfo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Data tiles", data);
        setProducts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching tiles:", error);
        setIsLoading(false);
      });
  }, [regionInfo, token]);

  const theme = useStyledTheme();

  return (
    <Panel theme={theme}>
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <>
          <Layout>
            <img
              src="unb2.png"
              className="img-fluid"
              alt="banner"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "100%",
                verticalAlign: "middle",
              }}
            />
          </Layout>
          <Layout justify="center">
            <LayoutItem>
              <Text as="h1" variant="display1" verticalAlign="center">
                Engineering Factory AD {regionInfo}
              </Text>
            </LayoutItem>
          </Layout>
          <Layout variant="flex" wrap="wrap">
            {products.map((product, index) => {
              // Skip hidden tiles
              if (product.hidden) {
                return null;
              }

              return (
                <LayoutItem
                  key={index}
                  basis={{ xs: "100%", l: "50%" }}
                  grow={{ xs: "1", l: "0" }}
                  shrink={{ xs: "1", l: "0" }}
                >
                  <ContentCard spacing={"m"}>
                    <Text variant="order3" spaceStackEnd="xxs">
                      {product.title}
                    </Text>
                    <Text variant="copy1">{product.content}</Text>
                    {/* Disable the button if the product is disabled */}
                    <Button
                      variant="secondary"
                      spaceStackStart="l"
                      href={product.url}
                      disabled={product.disabled}
                    >
                      More...
                    </Button>
                  </ContentCard>
                </LayoutItem>
              );
            })}
          </Layout>
        </>
      )}
    </Panel>
  );
}

export default Home;
